<template>
  <div name="patients" class="d-flex flex-column" :class="{ 'has-search-result': busy || patient }">
    <v-row ref="toolbar" no-gutters class="toolbar pa-4 light-blue lighten-2" justify="center" align="center">
      <!-- Organization Selector -->
      <v-select
        outlined
        background-color="white"
        :items="orgs"
        item-value="id"
        item-text="name"
        placeholder="Select an organization"
        hide-details
        class="org-selector mr-2"
        style="max-width: 320px"
        v-model="orgId"
        @change="orgSelectedHandler"
      ></v-select>

      <!-- Search TextFiled for Patient -->
      <v-text-field
        ref="searchTextField"
        v-if="orgId"
        class="search-textfield font-weight-medium"
        placeholder="Type Patient ID then press ENTER"
        height="56"
        solo
        flat
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        :disabled="!orgId"
        :loading="busy"
        v-model="patientRefId"
        @input="clearResults"
        @click:clear="patientRefId = ''"
        @keypress.enter="searchForPatient"
      ></v-text-field>

      <!-- Placeholder for Search TextField for Patient -->
      <v-tooltip v-else bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            style="cursor: wait; height: 56px; opacity: 0.5"
            class="search-textfield d-flex flex-grow-1 align-center light-blue darken-3 flex-grow-0 rounded white--text px-4"
          >
            <v-icon color="white" left>mdi-magnify</v-icon>
            <span>Type Patient ID then press ENTER</span>
          </div>
        </template>
        <span>Please select an organization</span>
      </v-tooltip>
    </v-row>

    <!-- {{ patient }} -->

    <v-scroll-y-transition>
      <div v-if="showResult && patient">
        <v-row no-gutters class="pa-5">
          <v-col cols="12" offset-md="3" md="6">
            <v-alert
              dark
              border="left"
              color="secondary"
              width="100%"
              :class="{ enrolled: patient.rpm.enrollment.enrolled }"
            >
              <v-row no-gutters>
                <v-icon size="48" class="mx-5">mdi-clipboard-account</v-icon>
                <div>
                  <v-row no-gutters align="center" class="title text-capitalize">
                    <span>{{ patient.firstName }} {{ patient.lastName }}</span>
                    <v-icon>mdi-circle-small</v-icon>
                    <span class="body-2">{{ patient.org.patientId }}</span>
                  </v-row>
                  <div class="subtitle-2 mt-n1">
                    <span>{{ orgsMap[patient.org.id] }}</span>
                    <v-icon>mdi-circle-small</v-icon>
                    <span v-if="patient.rpm.enrollment.enrolled">Enrolled</span>
                    <span v-else>Archived</span>
                  </div>
                </div>
              </v-row>
            </v-alert>
          </v-col>
          <v-col v-if="patient.rpm.enrollment.enrolled" cols="12" offset-md="3" md="6">
            <v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark large color="red" class="px-6" depressed @click="archive">Archive Patient</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<style lang="scss">
[name='patients'] {
  height: 100%;
  transition: height 0.3s;
  transition-delay: 200ms;

  .toolbar {
    margin-top: -10%;
    transition: margin-top 0.3s;
    transition-delay: 200ms;
  }

  &.has-search-result {
    height: 80px;

    .toolbar {
      margin-top: 0;
    }
  }

  .search-textfield {
    max-width: 350px;
    background: #1e88e53a !important;
  }

  .v-input--is-disabled > .v-input__control {
    opacity: 0;
  }

  .org-selector * {
    font-weight: 500;
    border: 0 !important;
  }

  .v-alert__border {
    border-color: #f41303 !important;
    opacity: 1;
  }

  .v-alert.enrolled .v-alert__border {
    border-color: #0bf403 !important;
    opacity: 1;
  }
}
</style>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { broadcastSnackbar } from '../shared-ui/hooks'
import { archivePatient } from '../services/admin'
import { state as orgState } from '../shared-ui/store/org'
import { state as patientState, getOrgPatient, clearPatient } from '../shared-ui/store/patient'
import { strcmp } from '../helpers/str'

export default defineComponent({
  setup() {
    const busy = ref(false)
    const busyButton = ref(false)
    const orgId = ref('')
    const showResult = ref(false)
    const patientRefId = ref('')
    const searchMode = ref(true)
    const searchTextField = ref(null)
    const orgs = computed(() => orgState.orgs.sort((a, b) => strcmp(a.name, b.name)))
    const orgsMap = computed(() => orgState.orgsMap)

    const archive = async () => {
      await archivePatient(patientState.patient.id)
      searchForPatient()
    }

    const searchForPatient = async () => {
      if (patientRefId.value) {
        busy.value = true
        try {
          await getOrgPatient(orgId.value, patientRefId.value)
          setTimeout(() => {
            showResult.value = true
          }, 500)
        } catch (e) {
          clearResults()
          broadcastSnackbar({
            icon: 'mdi-alert',
            text: 'Patient not found',
            showClose: true,
          })
        }
        busy.value = false
      }
    }

    const clearResults = () => {
      clearPatient()
      // patientRefId.value = ''
      showResult.value = false
    }

    const patient = computed(() => {
      if (patientState.patient) {
        return patientState.patient
      }
      return null
    })

    const orgSelectedHandler = () => {
      clearResults()
      setTimeout(() => {
        searchTextField.value.focus()
      }, 300)
    }

    clearResults()

    return {
      archive,
      busy,
      busyButton,
      clearResults,
      orgId,
      orgs,
      orgsMap,
      patient,
      patientRefId,
      searchTextField,
      searchForPatient,
      searchMode,
      orgSelectedHandler,
      showResult,
    }
  },
})
</script>