import { reactive, readonly } from '@vue/composition-api'

import { HttpClient } from '../shared-ui/helpers/httpClient'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { beforeRequestHandler } from '../shared-ui/store/sso'
import { onAuthChanged } from '../shared-ui/hooks'

if (!process.env.VUE_APP_ADMIN_API_URL) {
  throw new Error('VUE_APP_ADMIN_API_URL not defined in .env')
}

export const adminClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ADMIN_API_URL,
  beforeRequestHandler,
})

export const setAdminApiUrl = url => {
  adminClient.config.baseUrl = url
}

Vue.use(VueCompositionAPI)

onAuthChanged(authUser => {
  if (!authUser) {
    _state.device = null
  }
})

const _state = reactive({
  device: null,
})
export const state = readonly(_state)

export const archivePatient = async patientId => {
  await adminClient.post('/ArchivePatient', {
    patientId,
  })
}

export const clearDevice = () => {
  _state.device = null
}

export const getDevice = async deviceRefId => {
  const { data } = await adminClient.post('/GetDevice', {
    deviceRefId,
  })
  _state.device = data.device
  return _state.device
}

export const replaceDevice = async (id, replacementKind, replacementRefId) => {
  await adminClient.post('/ReplaceDevice', {
    id,
    replacementKind,
    replacementRefId,
  })
}

export const unassignDevice = async (id, orgAlso=false) => {
  await adminClient.post('/UnassignDevice', {
    id,
    unassignFromOrg: orgAlso,
  })
  _state.device.patientId = null
  if (orgAlso) _state.device.orgId = null
}
